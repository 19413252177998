import { FloorPlanTypeInputEnum, SearchBuildingsWhereInput } from "~/generated/v2/graphql";

export class WheresBuilder {
  static byIds(roomIds: number[]): SearchBuildingsWhereInput {
    return {
      search_rooms: {
        some: {
          id: { in: roomIds },
        },
      },
    };
  }

  static nearbyStation(
    stationId: number,
    excludeBuildingIds?: number[],
  ): SearchBuildingsWhereInput {
    return {
      AND: [
        { search_stations: { some: { station_id: { equals: stationId } } } },
        { id: { notIn: excludeBuildingIds } },
      ],
    };
  }

  static closeInSize(squareMeters: number): SearchBuildingsWhereInput {
    return {
      search_rooms: {
        some: {
          square_meters: {
            gte: squareMeters - 5,
            lte: squareMeters + 5,
          },
        },
      },
    };
  }

  static freeBrokerFee(): SearchBuildingsWhereInput {
    return {
      search_rooms: {
        some: {
          room_count: { equals: 1 }, // TODO: 不要では？
          broker_fee: { equals: 0 },
          rent_administration_fee: { gte: 80000, lte: 180000 },
        },
      },
    };
  }

  static single(): SearchBuildingsWhereInput {
    return {
      search_rooms: {
        some: {
          AND: [
            { room_count: { equals: 1 } },
            { rent_administration_fee: { gte: 50000, lte: 120000 } },
            {
              OR: [
                { floor_plan_name: { equals: FloorPlanTypeInputEnum.R } },
                { floor_plan_name: { equals: FloorPlanTypeInputEnum.K } },
                { floor_plan_name: { equals: FloorPlanTypeInputEnum.Dk } },
                { floor_plan_name: { equals: FloorPlanTypeInputEnum.Ldk } },
              ],
            },
          ],
        },
      },
    };
  }

  static double(): SearchBuildingsWhereInput {
    return {
      search_rooms: {
        some: {
          AND: [
            { rent_administration_fee: { gte: 100000, lte: 200000 } },
            {
              OR: [
                {
                  room_count: { equals: 1 },
                  floor_plan_name: { equals: FloorPlanTypeInputEnum.Ldk },
                },
                {
                  AND: [
                    { room_count: { equals: 2 } },
                    {
                      OR: [
                        { floor_plan_name: { equals: FloorPlanTypeInputEnum.K } },
                        { floor_plan_name: { equals: FloorPlanTypeInputEnum.Dk } },
                        { floor_plan_name: { equals: FloorPlanTypeInputEnum.Ldk } },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
    };
  }
}
